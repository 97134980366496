import { Skeleton } from '@repo/ui/components/ui/skeleton';
import Stack from '@repo/ui/components/Stack';
import { cn } from '@repo/ui/lib/utils';

export default function SkeletonGlobal() {
  return (
    <div className="border-gray-200 dark:bg-grayDark-800 dark:border-grayDark-600 font-inter h-[calc(100vh-64px)]">
      <section className="w-full">
        <nav
          className={cn(
            'flex bg-surface bg-surface border-b border-solid border-gray-200',
            'dark:bg-grayDark-800 dark:border-grayDark-600 h-[64px] items-center',
          )}
        >
          <div className="base:px-4 sm:px-6 min-w-full flex flex-row justify-between">
            <Skeleton className="w-28 h-10" />
            <Skeleton className="w-44 h-10" />
          </div>
        </nav>
      </section>
      <section className="h-full">
        <div className="sidebar flex-1">
          <Stack className="w-full justify-between">
            <Stack className="space-y-12">
              <Skeleton className="w-36 h-5" />
              <div className="space-y-6">
                <Skeleton className="w-28 h-5" />
                {Array.from({ length: 3 }).map((_, index) => (
                  <Skeleton key={index} className="w-32 h-6" />
                ))}
              </div>
              <div className="space-y-6">
                <Skeleton className="w-28 h-5" />
                {Array.from({ length: 3 }).map((_, index) => (
                  <Skeleton key={index} className="w-32 h-6" />
                ))}
              </div>
            </Stack>
          </Stack>
        </div>
      </section>
    </div>
  );
}
