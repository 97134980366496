import { useEffect } from 'react';

function useRemoveDarkModeFromLocalStorage() {
  useEffect(() => {
    const colorMode = localStorage.getItem('theme');
    if (colorMode === 'dark') {
      localStorage.setItem('theme', 'light');
    }
  }, []);
}

export default useRemoveDarkModeFromLocalStorage;
