import Link from 'next/link';

import type { IconType } from 'react-icons';
import { Check, Copy } from 'lucide-react';
import { useCopyToClipboard } from '@repo/ui/lib/useCopyToClipboard';
import { Button } from '@repo/ui/components/ui/button';

interface ContactItemProps {
  icon: IconType;
  text: string;
  url: string;
}

function ContactItem({ icon, text, url }: ContactItemProps) {
  const Icon = icon;
  const { isCopied, copyToClipboard } = useCopyToClipboard({ timeout: 750 });

  const onCopy = () => {
    if (isCopied) return;
    copyToClipboard?.(url);
  };

  return (
    <div className="flex items-center flex-row justify-between w-full">
      <div className="flex flex-row items-center pl-3">
        <Icon className="mr-2 text-brand-primary-hover dark:text-brand-primary" />
        <Link
          className="text-gray-600 dark:text-whiteAlpha-800 border-b border-solid border-transparent hover:no-underline hover:border-inherit text-sm"
          href={url}
        >
          {text}
        </Link>
      </div>
      <Button
        aria-label="copy"
        variant="ghost"
        size="icon-sm"
        onClick={onCopy}
        className="text-gray-400 hover:text-brand-primary dark:text-whiteAlpha-500 dark:hover:text-brand-primary"
      >
        {isCopied ? <Check className="h-4 w-4" /> : <Copy className="h-4 w-4" />}
      </Button>
    </div>
  );
}

export default ContactItem;
