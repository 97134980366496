'use client';

import { useState } from 'react';

export function useDisclosure(initial = false) {
  const [value, setValue] = useState(initial);

  return {
    onOpen: () => setValue(true),
    onClose: () => setValue(false),
    onToggle: () => setValue(prev => !prev),
    setValue,
    isOpen: value,
  };
}
