'use client';
export function GlobalStyles({ fontClass }: { fontClass: string }) {
  // ts-ignore
  return (
    <style jsx global>
      {`
      :root {
        --font-inter: ${fontClass};
      }
    `}
    </style>
  );
}
