'use client';

import { createContext, useContext, type ReactNode, useMemo, useState, useCallback, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

type UserPermissionsContextType = {
  isOrganizationAdministrator: boolean;
  isGlobalAdministrator: boolean;
  adminMode: boolean;
  setAdminMode: Function;
};

export const UserPermissionsContext = createContext<UserPermissionsContextType>({
  isOrganizationAdministrator: false,
  isGlobalAdministrator: false,
  adminMode: false,
  setAdminMode: () => {},
});

const useUserPermissionsContext = () => {
  const context = useContext(UserPermissionsContext);
  if (context === undefined) {
    throw new Error('useUserPermissionsContext must be used within a UserPermissionsProvider');
  }
  return context;
};

const hasOrganizationReadWriteAll = ['Organization Administrator', 'Inkeep Global Administrator'];
const adminModeEnabledKey = '_ame';

// todo switch to using permissions instad of roles
function UserPermissionsProvider({ children }: { children: ReactNode }) {
  const { user } = useAuth0();
  const userRoles: string[] = (user && user['https://inkeep.com:roles']) || [];

  const isOrganizationAdministrator = hasOrganizationReadWriteAll.some(role => userRoles.includes(role));

  const isGlobalAdministrator = userRoles.includes('Inkeep Global Administrator');

  const [adminMode, setAdminMode] = useState<boolean>(false);

  const setAdminModeHandler = useCallback(
    (newValue: boolean) => {
      localStorage.setItem(adminModeEnabledKey, String(newValue));

      setAdminMode(newValue);
    },
    [setAdminMode],
  );

  useEffect(() => {
    const adminModeEnabled = localStorage.getItem(adminModeEnabledKey) === 'true';

    if (adminModeEnabled) {
      setAdminMode(adminModeEnabled);
    }
  }, []);

  const value = useMemo(
    () => ({
      isOrganizationAdministrator,
      isGlobalAdministrator,
      adminMode,
      setAdminMode: setAdminModeHandler,
    }),
    [isOrganizationAdministrator, isGlobalAdministrator, adminMode, setAdminModeHandler],
  );

  return <UserPermissionsContext.Provider value={value}>{children}</UserPermissionsContext.Provider>;
}

export { UserPermissionsProvider, useUserPermissionsContext };
