import React from 'react';
import { cn } from '../lib/utils';

interface IProps extends React.PropsWithChildren {
  direction?: 'row' | 'row-reverse' | 'col' | 'col-reverse';
  className?: string;
}

function Stack({ children, direction = 'col', className }: IProps) {
  const directionMap = {
    row: 'flex-row space-x-2',
    col: 'flex-col space-y-2',
    'row-reverse': 'flex-row-reverse space-x-2',
    'col-reverse': 'flex-row-reverse space-y-2',
  };
  return <div className={cn('flex', directionMap[direction], className)}>{children}</div>;
}

export default Stack;
