import { useParams, useRouter } from 'next/navigation';
import { useContext } from 'react';
import { GlobalContext } from '@/context/GlobalContext';
import { Combobox } from '@repo/ui/components/ui/combobox';

function OrganizationSwitcher() {
  const router = useRouter();
  const params = useParams();
  const { organizationAlias } = params;
  const { setImitatedOrganization, organizationsMeta } = useContext(GlobalContext);

  const handleSelect = (value: string) => {
    setImitatedOrganization({
      alias: value,
    });
    router.push(`/${value}/projects`);
  };

  return (
    <div className="flex items-center gap-2">
      <Combobox
        options={organizationsMeta.map(organization => ({
          value: organization.alias,
          label: `${organization.displayName || organization.id} (${organization.alias})`,
        }))}
        onSelect={handleSelect}
        defaultValue={organizationAlias?.toString()}
        placeholder="Loading..."
        notFoundMessage="Organization not found"
      />
    </div>
  );
}

export default OrganizationSwitcher;
