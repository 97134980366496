import { useUserPermissionsContext } from '@/providers/UserPermissionsProvider';
import Stack from '@repo/ui/components/Stack';
import { cn } from '@repo/ui/lib/utils';
import Link from 'next/link';
import OrganizationSwitcher from '../Sidebar/OrganizationSwitcher';
import ContactButton from './ContactButton';
import Logo from './Logo';
import NavMenu from './NavMenu';
import FakeColorModeToggle from './TemoFakeColorModeToggle';

export default function NavBar() {
  const { adminMode, setAdminMode, isGlobalAdministrator } = useUserPermissionsContext();
  return (
    <section className="w-full">
      <nav
        className={cn(
          'flex bg-surface bg-surface border-b border-solid border-gray-200',
          'dark:bg-grayDark-800 dark:border-grayDark-600 h-[64px] items-center',
        )}
      >
        <div className="base:px-4 sm:px-6 min-w-full">
          <div className="flex justify-between items-center h-full">
            <Link href="/">
              <Logo />
            </Link>
            <Stack direction="row" className="space-x-2 items-center">
              <div>{isGlobalAdministrator && adminMode && <OrganizationSwitcher />}</div>
              {isGlobalAdministrator && (
                <FakeColorModeToggle onClick={() => setAdminMode(!adminMode)} isOn={adminMode} className="w-12" />
              )}
              <ContactButton />
              <NavMenu />
            </Stack>
          </div>
        </div>
      </nav>
    </section>
  );
}
