import { useContext, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useRouter, usePathname } from 'next/navigation';
import { useQuery } from '@apollo/client';
import { GlobalContext } from '@/context/GlobalContext';
import { GET_ORG_DEFAULTS, GET_USER_DEFAULT_PROJECT_ID } from '@/schemes';

function useRoutingListener() {
  const { imitatedOrganization } = useContext(GlobalContext);
  const { user } = useAuth0();
  const { data, loading, error } = useQuery(GET_ORG_DEFAULTS);
  const { data: userDefaultProjectIdData, loading: userDefaultLoading } = useQuery(GET_USER_DEFAULT_PROJECT_ID, {
    variables: {
      userId: user?.sub,
    },
  });

  const router = useRouter();
  const pathname = usePathname();
  const orgAlias = imitatedOrganization.alias || data?.organization.alias || null;
  const orgDefaultProjectId = data?.organization?.portalDisplayPreferences?.defaultProject?.id;
  const userDefaultProjectId = userDefaultProjectIdData?.user?.defaultProject?.id;
  const firstProjectId = data?.allProjects[0]?.id || null;

  const defaultProjectId = userDefaultProjectId || orgDefaultProjectId || firstProjectId;

  useEffect(() => {
    if (loading) {
      return;
    }

    if (error) {
      console.error("Couldn't fetch organization defaults");
      router.push('/error');
      return;
    }

    if ((pathname === '/' || pathname === '/error') && !error) {
      if (orgAlias && defaultProjectId) {
        router.push(`/${orgAlias}/projects/${defaultProjectId}/chat/sandbox`);
      } else if (orgAlias && !defaultProjectId) {
        router.push(`/${orgAlias}/projects/`);
      } else {
        console.error("Couldn't find org alias or default Integration ID");
        router.push('/error');
      }
    }

    if (pathname === '/404') {
      if (orgAlias) {
        router.push(`/${orgAlias}/projects/`);
      } else {
        console.error("Couldn't find org alias");
        router.push('/error');
      }
    }

    if (pathname !== '/' && error) {
      router.push('/');
    }
  }, [orgAlias, loading, defaultProjectId, router, error, data?.organization?.alias]);

  return { loading: userDefaultLoading || loading }; // export to use hook values outside
}

export default useRoutingListener;
