import { cn } from '@repo/ui/lib/utils';
import { Button } from '@repo/ui/components/ui/button';
import { MdOutlineDarkMode, MdOutlineLightMode } from 'react-icons/md';

function FakeColorModeToggle({
  onClick,
  isOn,
  className,
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
}: { onClick: any; isOn: boolean; className?: string }) {
  const Icon = !isOn ? MdOutlineLightMode : MdOutlineDarkMode;
  return (
    <Button
      variant="ghost"
      size="icon"
      onClick={onClick}
      aria-label="Toggle color mode"
      className={cn('w-full', className)}
    >
      {' '}
      <Icon />
    </Button>
  );
}

export default FakeColorModeToggle;
