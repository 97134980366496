import { cn } from '../lib/utils';
import type { SVGAttributes } from 'react';
import type { IconBaseProps, IconType } from 'react-icons/lib';
import type { LucideIcon } from 'lucide-react';

interface IconProps extends SVGAttributes<SVGElement> {
  as: IconType | LucideIcon;
}

export function Icon({ as: IconComponent, className, ...props }: IconProps & IconBaseProps) {
  return <IconComponent className={cn('w-[1em] h-[1em] outline-none', className)} {...props} />;
}
