'use client';

import { useTheme } from 'next-themes';
import { useEffect, useState } from 'react';
/**
 * Returns value based on current theme. (not currently used but keeping in case we need it later)
 */
export default function useColorThemeValue<LightData, DarkData>(light: LightData, dark: DarkData) {
  const { resolvedTheme } = useTheme();
  const [value, setValue] = useState<LightData | DarkData>(light);

  useEffect(() => {
    setValue(resolvedTheme === 'dark' ? dark : light);
  }, [resolvedTheme, light, dark]);

  return value;
}
