import { MdExpandMore, MdExpandLess } from 'react-icons/md';
import { useAuth0 } from '@auth0/auth0-react';
import { useDisclosure } from '@repo/ui/hooks/useDisclosure';
import { Button } from '@repo/ui/components/ui/button';
import { Icon } from '@repo/ui/components/Icon';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@repo/ui/components/ui/dropdown-menu';

export default function NavBar() {
  const isBrowser = typeof window !== 'undefined';
  const { user, logout } = useAuth0();
  const { isOpen, onClose, onToggle } = useDisclosure();

  return (
    <DropdownMenu open={isOpen} onOpenChange={onClose}>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="flex items-center text-gray-700 dark:text-whiteAlpha-700 font-normal text-md"
          onClick={onToggle}
        >
          {user?.name}{' '}
          <Icon
            as={isOpen ? MdExpandLess : MdExpandMore}
            className="w-5 h-5 text-gray-500 dark:text-whiteAlpha-500 ml-1"
          />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem
          className="text-md"
          onClick={() =>
            logout({
              logoutParams: {
                returnTo: isBrowser ? window.location.origin : '',
              },
            })
          }
        >
          Logout
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
