import type { HTMLAttributes, PropsWithChildren } from 'react';
import { cn } from '../lib/utils';

interface CenterProps extends HTMLAttributes<HTMLDivElement> {}
export function Center({ className, children, ...props }: CenterProps & PropsWithChildren) {
  return (
    <div className={cn('flex items-center justify-center', className)} {...props}>
      {children}
    </div>
  );
}
