/* eslint-disable no-unused-vars */

'use client';

import { type PropsWithChildren, createContext, useCallback, useEffect, useMemo, useState } from 'react';
import type { Organization } from '@/__generated__/graphql';

type ImitatedOrganizationPayload = {
  alias: string;
};

const locallyImitatedOrganizationKey = '_lio';

export const initialGlobalState = {
  imitatedOrganization: {
    alias: '',
  },
  setImitatedOrganization: (() => {}) as (arg0: ImitatedOrganizationPayload) => void,
  organizationsMeta: [] as Organization[],
  setOrganizationsMeta: (() => {}) as (arg0: Organization[]) => void,
};

export const GlobalContext = createContext(initialGlobalState);

// Simple global state for app, to avoid redux clutter.
export function GlobalContextProvider({ children }: PropsWithChildren) {
  const [imitatedOrganization, setImitatedOrganization] = useState({
    alias: '',
  });
  const [organizationsMeta, setOrganizationsMeta] = useState<Organization[]>([]);

  const setImitatedOrganizationHandler = useCallback(
    ({ alias }: ImitatedOrganizationPayload) => {
      localStorage.setItem(locallyImitatedOrganizationKey, alias);

      setImitatedOrganization(prev => ({
        ...prev,
        alias,
      }));
    },
    [setImitatedOrganization],
  );

  const values = useMemo(
    () => ({
      imitatedOrganization,
      setImitatedOrganization: setImitatedOrganizationHandler,
      organizationsMeta,
      setOrganizationsMeta,
    }),
    [imitatedOrganization, setImitatedOrganizationHandler, organizationsMeta, setOrganizationsMeta],
  );

  useEffect(() => {
    const locallyImitatedOrganization = localStorage.getItem(locallyImitatedOrganizationKey);

    if (locallyImitatedOrganization) {
      const alias = locallyImitatedOrganization;

      setImitatedOrganization(prev => ({ ...prev, alias }));
    }
  }, []);

  return <GlobalContext.Provider value={values}>{children}</GlobalContext.Provider>;
}
